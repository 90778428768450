import React from 'react';
import {Link} from 'react-router-dom';

import {Row,Col,Container,Form,InputGroup,Button,Tab,Nav,Image,Badge,} from 'react-bootstrap';
import ItemsCarousel from './common/ItemsCarousel';
import GalleryCarousel from './common/GalleryCarousel';
import CheckoutItem from './common/CheckoutItem';
import MenuItem from './common/MenuItem';
import QuickBite from './common/QuickBite';
import StarRating from './common/StarRating';
import RatingBar from './common/RatingBar';
import Review from './common/Review';
import Icofont from 'react-icofont';
import Moment from 'moment';
import {toCurrentTime,isInBetween,toClientTime,findDateFromDay} from "../services/DataFormatHandler";

import CategoriesCarousel from './common/CategoriesCarousel';
import ViewMenuDiscriptionModel from './modals/ViewMenuDiscriptionModal';
import Map from '../components/location/map'
class RestaurantTab extends React.Component {
	constructor(props, context) {
	    super(props, context);

	    this.state = {	
			status:this.props.status,
		  profile:this.props.profile
		  
	    };
	
        this.categories=[]
	
 
    }


        render() {
            let categories=this.props.profile.tagged_categories;
            return (

<Tab.Pane eventKey="third"  className="mt-4">
						            	<div id="restaurant-info" className="bg-white rounded shadow-sm p-4 mb-4">
			                              

												
			                              <h5 className="mb-4">Store Info</h5>
			                              <p className="mb-3">
                                              {typeof(this.props.profile.address!='undefined')&&this.props.profile.address}
                                             
			                              </p>
										  <p></p>
			                              <p className="mb-2 text-black"><Icofont icon="phone-circle text-primary mr-2" />{typeof(this.props.profile.phone!='undefined')&&this.props.profile.phone}
                                          {typeof(this.props.profile.alternative_phone!='undefined')&&","+this.props.profile.alternative_phone}
                                          </p>
			                              <p className="mb-2 text-black"><Icofont icon="email text-primary mr-2" />  {typeof(this.props.profile.store_email!='undefined')&&this.props.profile.store_email}</p>
										  { !this.props.profile.enable_separate_delivery_schedule &&
											<h6>Takeout & Delivery</h6>
										  }
										  { this.props.profile.enable_separate_delivery_schedule &&
										    this.props.profile.availableDaysForTakeout &&
											<h6>Takeout</h6>
										  }
										  { (this.props.profile.availableDaysForTakeout) && 
										 	Object.keys(this.props.profile.availableDaysForTakeout).map((key, index) => {
										 		return  (
												<p class="mb-2 text-black">
													<i class="icofont-clock-time text-primary mr-2"></i> 
													{key} 
													{}
													{ this.props.profile.availableDaysForTakeout[key].map((slotObj,idx)=>{
														let copentime=toClientTime(this.props.profile.time_zone,(findDateFromDay(key)+" "+slotObj['opening_time']))
														let cclosetime=toClientTime(this.props.profile.time_zone,(findDateFromDay(key)+" "+slotObj['closing_time']))
														let time_string=" "+copentime.format('hh:mm A')+"-"+cclosetime.format('hh:mm A');

														if(idx!=this.props.profile.availableDaysForTakeout[key].length-1) {
															time_string=	time_string+",";
														}
														return <span class="ml-1 myfont"> {time_string} </span>
													})
													}
 												</p>
												)})
										  }
										  { this.props.profile.enable_separate_delivery_schedule &&
										    this.props.profile.availableDaysForDelivery &&
											<h6>Delivery</h6>
										  }
										  { this.props.profile.enable_separate_delivery_schedule &&
										    (this.props.profile.availableDaysForDelivery) && 
										 	Object.keys(this.props.profile.availableDaysForDelivery).map((key, index) => {
										 		return  (
												<p class="mb-2 text-black">
													<i class="icofont-clock-time text-primary mr-2"></i> 
													{key} 
													{}
													{ this.props.profile.availableDaysForDelivery[key].map((slotObj,idx)=>{
														let copentime=toClientTime(this.props.profile.time_zone,(findDateFromDay(key)+" "+slotObj['opening_time']))
														let cclosetime=toClientTime(this.props.profile.time_zone,(findDateFromDay(key)+" "+slotObj['closing_time']))
														let time_string=" "+copentime.format('hh:mm A')+"-"+cclosetime.format('hh:mm A');

														if(idx!=this.props.profile.availableDaysForDelivery[key].length-1) {
															time_string=	time_string+",";
														}
														return <span class="ml-1 myfont"> {time_string} </span>
													})
													}
 												</p>
												)})
										  }
			                              <hr className="clearfix" />
			                           </div>
						          	</Tab.Pane>
            )}
	}
    export default RestaurantTab;