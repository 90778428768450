import React, { Component } from 'react';

import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
const API_KEY="AIzaSyCAQBQvrdz8mU3j_zYpxIcdm1yN2N2RUAU";
class Map extends Component {

  constructor(props, context) {
    super(props, context);
    
  }
   render() {
 

   const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
        defaultCenter = { { lat: this.props.lat, lng:this.props.lng } }
        defaultZoom = { 13 }
      >

<Marker google={this.props.google}
       name={'location'}
          draggable={false}
             position={{ lat:  this.props.lat, lng: this.props.lng }}
      />
     
  
      </GoogleMap>
   ));
   return(
      <div>
        <GoogleMapExample
          containerElement={ <div style={{ height: this.props.height, width: this.props.width }} /> }
          mapElement={ <div style={{ height: `100%` }} /> }
        />
      </div>
   );
   }
};
export default Map;